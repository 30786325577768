import React from 'react'
import { Helmet } from "react-helmet"
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';

import Layout from '../components/layout'
import ProductForm from '../components/ProductForm'
import ContextConsumer from '../components/context'

import media from '../common/mediaQuery'
import { theme } from '../common/global'
import { Type } from '../common/type'

export default ({ data }) => {
  // // Required check for no data being returned
  // const doc = data.prismic.allProducts.edges.slice(0,1).pop();
  // const product = data.shopifyProduct;
  // if (!doc) return null;

  // const params = {
  //   // Provide Swiper class as props
  //   Swiper,
  //   centeredSlides: true,
  //   effect: 'fade',
  //   loop: true,
  //   slidesPerView: 1,
  //   loopFillGroupWithBlank: false,
  //   shortSwipes: false,
  //   simulateTouchMove: false,
  //   threshold: 3,
  //   watchOverflow: true,
  //   navigation: {
  //     nextEl: '.swiper-button-next',
  //     prevEl: '.swiper-button-prev',
  //   },
  //   pagination: {
  //     el: '.swiper-pagination',
  //     type: 'bullets',
  //   },
  // }

  return (
    <Layout>
      <Helmet>
        <title>Elkin Editions</title>
        <meta name="description" content="Elkin Editions is an independent video production studio based in Lower Manhattan." />
        <meta property="og:image" content="../../share-image.jpg"></meta>
      </Helmet>
      <ContextConsumer>
        {({ contextData }) => (
          <PageWrapper isInverted={process.browser && localStorage.getItem('page_color')}>
            <Navigation>
              <Link to={`/`}>
                <Type>back to store ↗</Type>
              </Link>
            </Navigation>
            {/* <Content>
              <ImageList>
                <Swiper {...params}>
                  {product.images.map(({ originalSrc }, index) => (
                    <ImageWrapper key={ index }>
                      <Image src={ originalSrc }/>
                      <div className="swiper-button-prev"></div>
                      <div className="swiper-button-next"></div>
                    </ImageWrapper>
                  ))}
                </Swiper>
                <div className="swiper-pagination"></div>
              </ImageList>
              <Meta>
                <Block>
                  <Type bold>{ product.title }</Type>
                  <Type>{ product.productType }</Type>
                </Block>
                <Block dangerouslySetInnerHTML={{ __html: product.descriptionHtml }} />
              </Meta>
              <Details>
                <ProductForm product={ product }/>
              </Details>
            </Content> */}
          </PageWrapper>
        )}
      </ContextConsumer>
    </Layout>
  );
}

const PageWrapper = styled.div`
  background-color: ${theme.colors.black};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 30px 25px 0;
  transition: all ${theme.speed.fast} ease-in-out;
  width: 100%;

  ${media.tablet`
    padding: 30px;
  `}

  ${media.tabletLarge`
    padding: 30px 0 0 30px;
  `}

  ${props => props.isInverted === 'true' && css`
    background-color: ${theme.colors.white};
    color: ${theme.colors.black};

    a, input, select {
      color: ${theme.colors.black};
    }

    svg {
      stroke: ${theme.colors.black};
    }

    .swiper-pagination-bullet {
      background-color: ${theme.colors.black} !important;
    }
  `}
`;

const Navigation = styled.div`
  width: 100%;
  z-index: 100;

  ${media.tablet`
    flex: 0 0 100%;
    padding-right: 10px;
  `}

  ${media.tabletLarge`
    flex: 0 0 27.4%;
    padding-right: 20px;
  `}
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  width: 100%;

  ${media.tablet`
    flex: 0 0 100%;
    width: 100%;
  `}

  ${media.tabletLarge`
    flex: 0 0 72.6%;
    margin-top: 0;
    width: 72.6%;
  `}
`;

const ImageList = styled.ul`
  flex: 0 0 100%;
  position: relative;
  width: 100%;

  ${media.tablet`
    margin-bottom: 20px;
  `}

  .swiper-container {
    overflow: visible;
    position: relative;
  }

  .swiper-button-prev {
    cursor: url('../../elkin-arrow-left.png'), auto;
    height: 100%;
    left: 0;
    position: absolute;
    top: 20px;
    width: 50%;
    z-index: 100;

    &:after {
      display: none;
    }
  }

  .swiper-button-next {
    cursor: url('../../elkin-arrow-right.png'), auto;
    height: 100%;
    position: absolute;
    right: 0;
    top: 20px;
    width: 50%;
    z-index: 100;

    &:after {
      display: none;
    }
  }

  .swiper-pagination {
    bottom: -90px;
    height: 25px;
    left: -40px;
    position: absolute;
    text-align: right;
    transform: rotate(90deg);
    white-space: nowrap;
    width: 100px;
    z-index: 1000;

    ${media.tablet`
      bottom: 35px;
      left: -80px;
      text-align: left;
      transform: rotate(-90deg);
    `}
  }

  .swiper-pagination-bullet {
    cursor: pointer;
    background-color: ${theme.colors.white};
    border: transparent;
    height: 15px;
    margin: 0 9px;
    opacity: .4;
    position: relative;
    width: 15px;
    
    &.swiper-pagination-bullet-active {
      opacity: 1;
    }
  }
`;

const ImageWrapper = styled.li`
  display: block;
  height: 0 !important;
  opacity: 0 !important;
  overflow: hidden;
  padding-top: 100%;
  position: relative;
  width: 100% !important;

  ${media.tabletLarge`
    padding-top: 56.25%;
  `}

  &.swiper-slide-active {
    opacity: 1 !important;
  }
`;

const Image = styled.img`
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 1px;
  width: 100%;
`;

const Meta = styled.div`
  margin-top: 60px;
  order: 1;
  width: 100%;

  ${media.tablet`
    flex: 0 0 60%;
    margin-top: 30px;
    padding-right: 60px;
  `}

  ${media.tabletLarge`
    flex: 0 0 49%;
    margin-top: 0;
    order: 0;
  `}
`;

const Details = styled.div`
  margin-left: auto;
  margin-top: 20px;
  width: 50%;

  ${media.tablet`
    flex: 0 0 50%;
    margin-top: 0;
    padding-right: 50px;
    width: 100%;
  `}

  ${media.tabletLarge`
    margin-left: 0;
  `}
`;

const Block = styled.div`
  margin-top: 15px;
  width: 100%;

  ${media.tablet`
    margin-top: 4px;
  `}

  +div {
    margin-top: 20px;
  }

  p, span {
    font-family: "Grotesk", sans-serif;
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: -0.045rem;
    margin: 0;

    ${media.tablet`
      line-height: 1.5rem;
    `}

    +p {
      margin-top: 20px;
    }
  }
`;

export const query = graphql`
query ProductQuery($uid: String) {
  prismic {
    allProducts(uid: $uid) {
      edges {
        node {
           _meta {
            uid
          }
          product
        }
      }
    }
  }
}
`