import React, { useState, useContext, useEffect, useCallback } from 'react'
import find from 'lodash/find'
import isEqual from 'lodash/isEqual'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import media from '../common/mediaQuery'
import { theme } from '../common/global'
import { Title, Type } from '../common/type'

import StoreContext from '../context/StoreContext'

const ProductForm = ({ product, padding, title }) => {
  const {
    options,
    variants,
    variants: [initialVariant],
    priceRange: { minVariantPrice },
  } = product
  const [variant, setVariant] = useState({ ...initialVariant })
  const [quantity, setQuantity] = useState(1)
  const {
    addVariantToCart,
    store: { client, adding },
  } = useContext(StoreContext)

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant
  const [available, setAvailable] = useState(productVariant.availableForSale)

  const checkAvailability = useCallback(
    productId => {
      client.product.fetch(productId).then(fetchedProduct => {
        // this checks the currently selected variant for availability
        const result = fetchedProduct.variants.filter(
          variant => variant.id === productVariant.shopifyId
        )
        if (result.length > 0) {
          setAvailable(result[0].available)
        }
      })
    },
    [client.product, productVariant.shopifyId, variants]
  )

  useEffect(() => {
    checkAvailability(product.shopifyId)
  }, [productVariant, checkAvailability, product.shopifyId])

  const handleQuantityChange = ({ target }) => {
    setQuantity(target.value)
  }

  const handleOptionChange = (optionIndex, { target }) => {
    const { value } = target
    const currentOptions = [...variant.selectedOptions]

    currentOptions[optionIndex] = {
      ...currentOptions[optionIndex],
      value,
    }

    const selectedVariant = find(variants, ({ selectedOptions }) =>
      isEqual(currentOptions, selectedOptions)
    )

    setVariant({ ...selectedVariant })
  }

  const handleAddToCart = () => {
    addVariantToCart(productVariant.shopifyId, quantity)
  }

  /* 
  Using this in conjunction with a select input for variants 
  can cause a bug where the buy button is disabled, this 
  happens when only one variant is available and it's not the
  first one in the dropdown list. I didn't feel like putting 
  in time to fix this since its an edge case and most people
  wouldn't want to use dropdown styled selector anyways - 
  at least if the have a sense for good design lol.
  */
  const checkDisabled = (name, value) => {
    const match = find(variants, {
      selectedOptions: [
        {
          name: name,
          value: value,
        },
      ],
    })
    if (match === undefined) return true
    if (match.availableForSale === true) return false
    return true
  }

  const price = Intl.NumberFormat(undefined, {
    currency: minVariantPrice.currencyCode,
    minimumFractionDigits: 0,
    style: 'currency',
  }).format(variant.price)

  return (
    <LeadContent>
      <Block>
        <Title static>{price}</Title>
        <div>
          <Type>Qty:</Type>
          <input
            type="number"
            id="quantity"
            name="quantity"
            min="1"
            max="5"
            step="1"
            onChange={handleQuantityChange}
            value={quantity}
          />
        </div>
        {options.map(({ id, name, values }, index) => (
          <div key={id}>
            {values.length > 1 &&
              <>
                <label htmlFor={name}>
                  <Type as="span">{name}: </Type>
                </label>
                <select
                  name={name}
                  key={id}
                  onChange={event => handleOptionChange(index, event)}
                >
                  {values.map(value => (
                    <option
                      value={value}
                      key={`${name}-${value}`}
                      disabled={checkDisabled(name, value)}
                    >
                      {value}
                    </option>
                  ))}
                </select>
              </>
            }
            <br />
          </div>
        ))}
      </Block>
      <Block>
        <ButtonWrapper disabled={!available || adding} onClick={handleAddToCart}>{available ? `add to cart` : 'sold out'}</ButtonWrapper>
      </Block>
    </LeadContent>
  )
}

ProductForm.propTypes = {
  product: PropTypes.shape({
    descriptionHtml: PropTypes.string,
    handle: PropTypes.string,
    id: PropTypes.string,
    shopifyId: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        originalSrc: PropTypes.string,
      })
    ),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        values: PropTypes.arrayOf(PropTypes.string),
      })
    ),
    productType: PropTypes.string,
    title: PropTypes.string,
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        availableForSale: PropTypes.bool,
        id: PropTypes.string,
        price: PropTypes.string,
        title: PropTypes.string,
        shopifyId: PropTypes.string,
        selectedOptions: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.string,
          })
        ),
      })
    ),
  }),
  addVariantToCart: PropTypes.func,
}

export default ProductForm

const LeadContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

const Block = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  
  h1 {
    width: 100%;
  }

  div {
    align-items: center;
    display: flex;
    margin-top: 25px;
    width: 100%;

    ${media.tablet`
      margin-top: 14px;
    `}

    +div {
      margin-top: 5px;
    }
  }

  input {
    border: 0;
    background-color: transparent;
    color: ${theme.colors.white};
    font-family: "GroteskBold", sans-serif;
    font-size: 1.3rem;
    font-weight: 400;
    margin: 0 0 0 10px;
    width: 60px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &[type=number] {
      -moz-appearance: textfield;
    }
  }

  select {
    cursor: pointer;
    -webkit-appearance: none;
    border: 0;
    color: ${theme.colors.white};
    font-family: "GroteskBold", sans-serif;
    font-size: 1.3rem;
    font-weight: 400;
    margin: -2px 0 0;
  }

  ${media.tablet`
    width: 50%;
  `}
`;

const ButtonWrapper = styled.button`
  cursor: pointer;
  background-color: ${theme.colors.yellow};
  border-radius: 6px;
  color: ${theme.colors.black};
  font-family: "GroteskBold", sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  height: 40px;
  line-height: 1rem;
  margin-top: 20px;
  padding: 6px 10px;
  position: relative;
  transition: background-color ${theme.speed.fast} ease-in-out;
  width: fit-content;

  ${media.tablet`
    align-self: flex-start;
    margin-left: auto;
    margin-top: 8px;
    padding: 10px 9px 16px;
  `}

  &:hover {
    background-color: ${theme.colors.lightYellow};
  }

  ${props => props.disabled && css`
    pointer-events: none;
  `}
`;
